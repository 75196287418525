import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {StoreService} from '../../../domain/store.service';
import {Store} from '../../../domain/models/store/store';
import {ActivatedRoute, Router} from '@angular/router';
import {ImageUtils} from '../../../utils/image.utils';
import {Category, CategoryType} from '../../../domain/models/category/category';
import {ProductService} from '../../../domain/product.service';
import {Product} from '../../../domain/models/product/product';
import {ForegroundPaths} from '../../../app-routing.module';
import {CartService} from '../../../domain/cart.service';
import {Location} from '@angular/common';
import {PaginationHandler} from '../../../domain/pagination/pagination.handler';
import {StoreProfile} from '../../../domain/models/store/store-profile';
import {CodeType, ScannerService, ScanResult} from '../../../domain/scanner.service';
import {Subscription, throwError} from 'rxjs';
import {AppComponent} from '../../../app.component';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {GTagService} from '../../../domain/gtag.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EanInputDialog} from '../../../dialogs/ean-input/ean-input.dialog';
import {LocalStorageService} from 'ngx-webstorage';
import {DimensionAvailability} from '../../../domain/models/product/dimension-availability';
import {DimensionAvailabilityService} from '../../../domain/dimension-availability.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AdjustmentTypeService} from '../../../domain/adjustment-type.service';
import {CustomerService} from '../../../domain/customer.service';
import {delay} from '../../../utils/promise.utils';
import {Microshop} from '../../../domain/models/microshop/microshop';
import {MicroshopService} from '../../../domain/microshop.service';
import {InvalidStoreDialog} from '../../../dialogs/invalid-store/invalid-store.dialog';
import {PaymentService} from '../../../domain/payment.service';
import {first} from 'rxjs/operators';
import {LoadingService} from '../../../domain/loading.service';
import {ComponentType} from '@angular/cdk/portal';
import {InvalidMicroshopDialog} from '../../../dialogs/invalid-microshop/invalid-microshop.dialog';
import {bugsnagStartSpan} from '../../../bugsnag';
import {httpUtils} from '../../../utils/http.utils';
import {WeightInputDialog} from '../../../dialogs/weight-input/weight-input.dialog';
import {Gs1Utils} from '../../../utils/gs1.utils';
import {EventLogService} from '../../../domain/event-log.service';

@Component({
  selector: 'app-store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.sass'],
})
export class StoreDetailsComponent implements OnInit, OnDestroy {

  StoreDetailsState = StoreDetailsState;
  state: StoreDetailsState = StoreDetailsState.Scan;
  store?: Store;
  microshop?: Microshop;
  profile?: StoreProfile;
  storeImage?: string;
  categories?: CategoryType[];
  dimensionAvailability: DimensionAvailability[] = [];
  availabilityCount: number | undefined;
  searchQuery?: string;
  currencyCode?: string;
  enableContinuousScanning?: boolean;
  showBackToScanButton = true;
  productBrowseOnly = false;
  enableProductSearch = false;
  currentSection?: string;
  scrollShadow = false;
  enableLogin = false;

  searchHandler = new PaginationHandler<Product>(page =>
    this.productService.searchProducts(this.storeHandle!, this.searchQuery!, page),
  );

  private readonly storeHandle: string | null;
  private readonly microshopHandle: string | null;
  private scannerSubscription?: Subscription;
  private foregroundSubscription?: Subscription;
  activeAvatar = false;
  static audio?: HTMLAudioElement;
  enableScrollSpy = true;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private storeService: StoreService,
              private productService: ProductService,
              private cartService: CartService,
              private scannerService: ScannerService,
              private location: Location,
              private titleService: Title,
              private gTagService: GTagService,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private dialog: MatDialog,
              private dimensionAvailabilityService: DimensionAvailabilityService,
              private localStorageService: LocalStorageService,
              private storageService: LocalStorageService,
              private adjustmentService: AdjustmentTypeService,
              public customerService: CustomerService,
              private microshopService: MicroshopService,
              private paymentService: PaymentService,
              private loadingService: LoadingService,
              private eventLogService: EventLogService,
  ) {
    this.storeHandle = this.route.snapshot.paramMap.get('id');
    this.microshopHandle = this.route.snapshot.paramMap.get('microshopHandle');
    this.storeService.storeId = this.storeHandle;
    document.body.addEventListener('click', this.unlockAudio);
    document.body.addEventListener('touchstart', this.unlockAudio);
  }

  async unlockAudio() {
    if (StoreDetailsComponent.audio == undefined) {
      StoreDetailsComponent.audio = new Audio('assets/audio/beep.mp3');
    }
    document.body.removeEventListener('click', this.unlockAudio);
    document.body.removeEventListener('touchstart', this.unlockAudio);
  }

  async soundNotification() {
    try {
      await this.unlockAudio();
      await StoreDetailsComponent.audio!.play().then(() => {
        StoreDetailsComponent.audio = new Audio('assets/audio/beep.mp3');
      });
    } catch (e) {
      console.log('Sound error: ' + e);
    }
  }

  async ngOnInit() {
    const span = bugsnagStartSpan('store-details:init');

    if (this.router.url.includes('/browse')) {
      this.state = StoreDetailsState.Browse;
    }

    if (this.storeHandle != null) {
      await this.initStore();
      await this.checkPendingAddCartProduct();
      this.checkDeviceId();
    }

    this.scannerService.scanner?.decoderInitialized
      // Only fire once when true
      .pipe(first(state => state, 1))
      .subscribe(state => {
        if (state && this.allowScanning()) {
          this.startScan();
        }
      });

    this.foregroundSubscription?.unsubscribe();
    this.foregroundSubscription = AppComponent.onForegroundChanged()
      .subscribe(() => {
        if (this.allowScanning()) {
          this.startScan();
        } else {
          this.stopScan();
        }
      });

    span?.end();
  }

  private async initStore() {
    this.loadingService.start();

    try {
      this.store = await this.storeService.getStore(this.storeHandle!);
    } catch (error) {
      this.loadingService.stop();
      if (error.status == 404) {
        this.openInvalidStoreDialog(InvalidStoreDialog);
      }
      throw error;
    }

    this.customerService.initCustomerFromAuth(this.store.storeChain.id);

    if (this.route.snapshot.queryParamMap.get('qr') == 'true') {
      this.handleQrEventLog(this.store.storeChain.id, this.store.id);
    }

    const persistedMicroshopHandle = this.microshopService.getPersistedMicroshopHandle(this.store.handle);

    if (this.microshopHandle) {
      await this.initMicroshop(this.store.handle, this.microshopHandle);
    } else if (this.state == StoreDetailsState.Browse && persistedMicroshopHandle) {
      await this.router.navigate([`store/${this.store.handle}/microshop/${persistedMicroshopHandle}/browse`], {replaceUrl: true});
    } else if (this.store.categories.length > 0) {
      this.categories = this.store.categories.filter(c => c.products.length > 0).map(c => c as Category);
      this.currentSection = this.categories[0].id;
    }

    await Promise.allSettled([
      this.checkContinuousScanningSetting(),
      this.checkProductBrowseSetting(),
      this.checkProductSearchEnabledSetting(),
      this.storeService.isGateEnabled(this.store.handle),
      this.storeService.isDiscountCodeEnabled(this.store.handle),
      this.paymentService.getDirectPaymentOption(this.store.id),
      this.checkLoginMethods(),
    ]);

    // This hack should be removed when the backend have implemented proper support for browse only stores: ref jira SCAN-1643
    if (this.state === StoreDetailsState.Scan && this.productBrowseOnly) {
      await this.router.navigate(['browse'], {relativeTo: this.route.parent?.firstChild});
    }

    this.handleExternalId();

    this.titleService.setTitle(`${this.store.title} | FYGI`);
    this.profile = this.store.storeProfile;
    this.currencyCode = this.store.currencyCode;

    this.activeAvatar = this.localStorageService.retrieve('avatar') != false;

    if (this.store.storeProfile.logoImage) {
      this.storeImage = ImageUtils.getImageUrl(this.store.storeProfile.logoImage, 200, false);
    }

    if (this.state === StoreDetailsState.Browse) {
      const initialQuery = this.route.snapshot.queryParams['search'];
      if (initialQuery != null) {
        await this.doSearch(this.storeHandle!, this.route.snapshot.queryParams['search']);
      }
    }

    this.loadingService.stop();

    if (this.store.adjustmentTypes !== null && this.store.adjustmentTypes.length > 0) {
      if (this.adjustmentService.noStoredType(this.store.handle)) {
        const productId = this.getProductIdFromPathIfAny();

        if (productId != null) {
          await this.router.navigate(ForegroundPaths.adjustmentChooserWithProduct(productId));
        } else {
          await this.router.navigate(ForegroundPaths.adjustmentChooser());
        }
      } else {
        await this.checkPendingAddCartProduct();
      }
    } else {
      await this.checkPendingAddCartProduct();
    }
  }

  private async initMicroshop(storeHandle: string, microshopHandle: string) {
    try {
      this.microshop = await this.microshopService.getMicroshop(storeHandle, microshopHandle);
      this.categories = this.microshop.categories;
      this.currentSection = this.categories[0]?.id;
    } catch (error) {
      this.loadingService.stop();
      this.microshopService.clearPersistedMicroshopHandle(storeHandle);
      if (error.status == 404) {
        this.openInvalidStoreDialog(InvalidMicroshopDialog);
      }
      throw error;
    }
    this.microshopService.persistMicroshopHandle(storeHandle, microshopHandle);
  }

  private allowScanning() {
    if (this.productBrowseOnly || !this.scannerService.scanner?.decoderInitialized.value) {
      return false;
    }

    // Ideally we should've used this.state == scan instead of checking /browse,
    // but this.state is not properly set in onScanResult callbacks
    return !this.router.url.includes('/browse') && document.visibilityState !== 'hidden' && !AppComponent.isForegroundOpen;
  }

  private async checkProductBrowseSetting() {
    if (this.store) {
      try {
        if (await this.storeService.isProductBrowseOnly(this.store.handle)) {
          this.showBackToScanButton = false;
          this.productBrowseOnly = true;
        }
      } catch (error) {
        if (error.status == 404) {
          this.showBackToScanButton = true;
        } else {
          throw error;
        }
      }
    }
  }

  private async checkContinuousScanningSetting() {
    if (this.store) {
      try {
        this.enableContinuousScanning = await this.storeService.isContinuousScanningEnabled(this.store.handle);
      } catch (error) {
        if (error.status == 404) {
          this.enableContinuousScanning = false;
        } else {
          throw error;
        }
      }
    }
  }

  private async checkProductSearchEnabledSetting() {
    if (this.store) {
      try {
        if (!this.microshopHandle && await this.storeService.isProductSearchEnabled(this.store.handle)) {
          this.enableProductSearch = true;
        }
      } catch (error) {
        if (error.status == 404) {
          this.enableProductSearch = false;
        } else {
          throw error;
        }
      }
    }
  }

  private getProductIdFromPathIfAny() {
    return this.route.parent?.children[1]!.snapshot.paramMap.get('productId');
  }

  ngOnDestroy(): void {
    this.scannerSubscription?.unsubscribe();
    this.foregroundSubscription?.unsubscribe();
  }

  async startScan() {
    let tutorialText: string | undefined;

    if (this.storeHandle != null) {
      if (this.storageService.retrieve('cart') == null) {
        tutorialText = await this.translateService.get('SCANNER.SCANPRODUCTS.description').toPromise();
      }
    }
    this.scannerSubscription?.unsubscribe();
    this.scannerSubscription = this.scannerService
      .onScanResult([CodeType.Code128, CodeType.EAN8, CodeType.EAN13, CodeType.QR, CodeType.UPCA], tutorialText)
      .subscribe(async result => {
        this.scannerSubscription?.unsubscribe();
        const storeHandle = this.storeHandle;

        await this.soundNotification();

        if (storeHandle) {
          if (result.symbologyName == CodeType.QR && result.barcodeData.includes('/microshop/')) {
            await this.handleMicroshop(result);
          } else if (result.symbologyName == CodeType.QR && result.barcodeData.includes('fygi.app')) {
            await this.handleFygiQr(result);
          } else if (result.symbologyName == CodeType.QR && /^https:\/\/qr(-mt)?\.vipps\.no/.test(result.barcodeData)) {
            window.location.href = result.barcodeData;
            return;
          } else if (result.symbologyName == CodeType.UPCA) {
            result.barcodeData = '0' + result.barcodeData;
            await this.handleDefaultBarcode(storeHandle, result);
          } else if (result.symbologyName == CodeType.Code128 && result.barcodeData === 'kebabfriday') {
            await this.router.navigate(ForegroundPaths.kebabFriday());
          } else {
            await this.handleDefaultBarcode(storeHandle, result);
          }
        }

        if (this.allowScanning()) {
          await this.startScan();
        }
      });
  }

  private handleQrEventLog(storeChainId: string, storeId: string) {
    const origin = window.location.href;
    this.router.navigate([], {queryParams: {qr: null}, queryParamsHandling: 'merge', replaceUrl: true});
    this.customerService.getCustomerId(storeChainId).then((customerId) => {
      this.eventLogService.logStoreQrScanned(customerId, storeId, origin);
    });
  }

  private async handleFygiQr(result: ScanResult) {
    const scannedData = result.barcodeData;
    const pathParts = scannedData?.split('/');
    const lastPathPart = pathParts[pathParts.length - 1];

    try {
      const product = await this.productService.getProductByHandle(this.storeHandle!, lastPathPart);
      await this.cartService.add(this.storeHandle!, product);
      await delay(1500); // Delay next scan
    } catch (reason) {
      if (reason instanceof HttpErrorResponse && (reason.status == 404)) {
        await this.handleUnknownProduct(result);
      } else {
        throwError(reason);
      }
    }
  }

  private async handleMicroshop(result: ScanResult) {
    const newUrl = new URL(result.barcodeData);
    if (window.location.hostname != newUrl.hostname) {
      window.location.href = result.barcodeData;
    } else {
      await this.router.navigate([newUrl.pathname]);
    }
  }

  private async handleDefaultBarcode(storeHandle: string, result: ScanResult) {
    try {
      const product = await this.productService.findByBarcode(storeHandle, result.barcodeData);
      let dimensionId = product.dimensions.find(t => t.barcode == result.barcodeData)?.id;
      if (!this.enableContinuousScanning && !product.isWeight) {
        await this.router.navigate(ForegroundPaths.productWithDimension(product.handle, dimensionId));
        return;
      }
      await this.checkAvailability(product);
      await this.cartService.add(storeHandle, product, dimensionId, undefined, undefined, result.barcodeData);
      await new Promise(resolve => setTimeout(resolve, 3500));
    } catch (error) {
      if (httpUtils(error).isStatus(404)) {
        await this.handleUnknownProduct(result);
      } else {
        throwError(error);
      }
    }
  }

  private async checkAvailability(product: Product) {
    const itemCount = await this.cartService.getItemCount(this.storeHandle!, product, product.dimensions[0]);
    this.dimensionAvailability = await this.dimensionAvailabilityService.getStock(this.storeHandle!, product.id);
    this.availabilityCount = this.dimensionAvailability[0].count;
    if (this.store != null && this.dimensionAvailability[0].trackStock && this.availabilityCount > itemCount || this.store != null && !this.dimensionAvailability[0].trackStock) {
    } else {
      const message = await this.translateService.get('PRODUCTDETAILS.noStock').toPromise();
      this.toastr.error(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
    }
  }

  private async handleUnknownProduct(result: ScanResult) {
    const symbology = await this.getFriendlySymbologyName(result.symbologyName);
    const message = symbology + await this.translateService.get('SCANNER.ERROR.unrecognized').toPromise();
    this.toastr.warning(message, undefined, {
      timeOut: 3000,
      easeTime: 100,
      positionClass: 'toast-bottom-center',
    });
    await new Promise(resolve => setTimeout(resolve, 5000));
  }

  private async getFriendlySymbologyName(symbology: CodeType): Promise<string> {
    switch (symbology) {
      case CodeType.QR:
        return await this.translateService.get('SCANNER.ERROR.QR').toPromise();
      case CodeType.Code128:
      case CodeType.EAN8:
      case CodeType.EAN13:
      case CodeType.UPCA:
      case CodeType.DATA_MATRIX:
        return await this.translateService.get('SCANNER.ERROR.BARCODE').toPromise();
    }
  }

  stopScan() {
    this.scannerSubscription?.unsubscribe();
  }

  async doSearch(storeHandle: string, query: string) {
    this.searchQuery = query;
    this.searchHandler.clear();
    if (query.length < 1) {
      return this.endSearch();
    }
    if (this.searchHandler.isBusy) {
      this.searchHandler.clear();
    }
    await this.searchHandler.getNext();
    this.updateUrlWithSearchQuery(query);
  }

  async endSearch() {
    this.searchQuery = undefined;
    this.searchHandler.clear();
    this.updateUrlWithSearchQuery();
    this.onScroll();
  }

  async onSearchScrolled(event: any) {
    if (this.searchHandler.isBusy) {
      return;
    }
    const bottomTriggerPoint = 150;
    this.scrollShadow = event.target.scrollTop > 5;
    if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - bottomTriggerPoint)) {
      if (this.searchHandler.hasNext()) {
        await this.searchHandler.getNext();
      }
    }
  }

  private updateUrlWithSearchQuery(query?: string) {
    const url = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: {search: query},
    }).toString();
    this.location.go(url);
  }

  async quickAddToCart(storeHandle: string, product: Product) {
    const span = bugsnagStartSpan('product-search:quickAddToCart');
    this.storageService.store('cart', true);

    let generatedScannedBarcode: string | undefined;
    if (product.isWeight && Gs1Utils.isValidProductBarcode(product.dimensions[0]?.barcode)) {
      const weight = await this.dialog.open(WeightInputDialog, {
        data: {unit: 'grams'},
      }).afterClosed().toPromise();
      if (!weight) {
        span?.end();
        return;
      }
      try {
        generatedScannedBarcode = Gs1Utils.generateWeightBarcode(product.dimensions[0].barcode, weight);
      } catch (error) {
        this.toastr.error(error, undefined, {positionClass: 'toast-bottom-center'});
        span?.end();
        return;
      }
    }

    const isShipmentDefault = product.fulfillmentOptions?.find(option => option.type === 'shipment')?.default;

    if (isShipmentDefault != undefined && !isShipmentDefault && this.store) {
      await this.router.navigate(ForegroundPaths.empty());
      await delay(80);
      await this.cartService.add(this.store.handle, product, undefined, undefined, undefined, generatedScannedBarcode);
      span?.end();
      return;
    }

    this.dimensionAvailability = await this.dimensionAvailabilityService.getStock(storeHandle, product.id);
    this.availabilityCount = this.dimensionAvailability[0].count;
    const itemCount = await this.cartService.getItemCount(storeHandle, product, product.dimensions[0]);

    if (this.store != null && this.dimensionAvailability[0].trackStock && this.availabilityCount > itemCount || this.store != null && !this.dimensionAvailability[0].trackStock) {
      await this.cartService.add(storeHandle, product, undefined, undefined, undefined, generatedScannedBarcode);
      this.gTagService.quickAdd(product.dimensions[0], product);
    } else {
      const message = await this.translateService.get('PRODUCTDETAILS.noStock').toPromise();
      this.toastr.error(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
    }
    span?.end();
  }

  async showEANInputDialog() {
    const barcode = await this.dialog.open(EanInputDialog).afterClosed().toPromise();

    if (barcode != null && barcode.toLowerCase() === 'kebabfriday') {
      await this.router.navigate(ForegroundPaths.kebabFriday());
    } else if (barcode != null) {
      const product = await this.productService.findByBarcode(this.storeHandle!, barcode).catch(_ => undefined);

      if (product) {
        if (!this.enableContinuousScanning && !product.isWeight) {
          await this.router.navigate(ForegroundPaths.product(product.handle));
          return;
        }

        const isShipmentDefault = product.fulfillmentOptions?.find(option => option.type === 'shipment')?.default;
        if (isShipmentDefault != undefined && !isShipmentDefault && this.store) {
          await this.router.navigate(ForegroundPaths.empty());
          await delay(80);
          await this.cartService.add(this.store.handle, product, undefined, undefined, undefined, barcode);
          return;
        }

        const itemCount = await this.cartService.getItemCount(this.storeHandle!, product, product.dimensions[0]);
        this.dimensionAvailability = await this.dimensionAvailabilityService.getStock(this.storeHandle!, product.id);
        this.availabilityCount = this.dimensionAvailability[0].count;
        if (this.store != null && this.dimensionAvailability[0].trackStock && this.availabilityCount > itemCount || this.store != null && !this.dimensionAvailability[0].trackStock) {
          const dimensionId = product.dimensions.find(t => t.barcode == barcode)?.id;
          await this.cartService.add(this.storeHandle!, product, dimensionId, undefined, undefined, barcode);
          await new Promise(resolve => setTimeout(resolve, 3500));
        } else {
          const message = await this.translateService.get('PRODUCTDETAILS.noStock').toPromise();
          this.toastr.error(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
        }
      } else {
        const error = await this.translateService.get('SCANNER.ERROR.notFound').toPromise();
        this.toastr.error(error, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
      }
    }
  }

  setAvatarStorage() {
    if (this.localStorageService.retrieve('avatar') == null || undefined) {
      this.localStorageService.store('avatar', false);
      this.activeAvatar = false;
    }
    this.localStorageService.retrieve('avatar');
  }

  private async checkPendingAddCartProduct() {
    const pendingProduct = this.localStorageService.retrieve('pendingAddCartProduct');
    this.localStorageService.clear('pendingAddCartProduct');
    if (pendingProduct != null) {
      const product = await this.productService.getProductByHandle(this.storeHandle!, pendingProduct);
      await this.cartService.add(this.storeHandle!, product);
    }
    this.localStorageService.retrieve('avatar');
  }

  async toggleBrowseMode() {
    if (this.state == StoreDetailsState.Browse) {
      await this.router.navigate([`store/${this.store?.handle}`]);
    } else {
      await this.router.navigate([`store/${this.store?.handle}/browse`]);
    }
  }

  openLogin() {
    this.customerService.openLoginDialog(this.store!.storeChain.id);
  }

  onSectionChange(sectionId: string) {
    if (this.enableScrollSpy && sectionId) {
      this.currentSection = sectionId;
      const btnElement = document.getElementById(`btn_${sectionId}`);

      if (btnElement) {
        document.getElementById('categoryDetails')?.scrollTo({left: btnElement.offsetLeft - 20, behavior: 'smooth'});
      }
    }
  }

  async scrollToSection(sectionId: string): Promise<void> {
    if (sectionId) {
      this.currentSection = sectionId;
      this.enableScrollSpy = false;
      const element = document.getElementById(sectionId);

      if (element) {
        document.body.scrollTo({top: element.offsetTop - 115, behavior: 'smooth'});
        await delay(1500);
      }

      this.enableScrollSpy = true;
    }
  }

  @HostListener('body:scroll')
  onScroll() {
    const scrollTop = document.body.scrollTop;
    this.scrollShadow = scrollTop > 115;
  }

  private openInvalidStoreDialog(dialogComponent: ComponentType<unknown>) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    this.dialog.open(dialogComponent, dialogConfig);
  }

  @HostListener('document:visibilitychange')
  async visibilityStateChanged() {
    if (this.productBrowseOnly) {
      return;
    }

    if (document.visibilityState === 'hidden') {
      this.stopScan();
    } else if (this.allowScanning()) {
      await this.startScan();
    }
  }

  private handleExternalId() {
    const externalId = this.route.snapshot.queryParams['externalId'];
    if (externalId && this.store) {
      this.storeService.setExternalId(this.store.handle, externalId);
    }
  }

  private async checkLoginMethods() {
    if (this.store) {
      const loginMethods = await this.storeService.getLoginMethods(this.store.handle);
      this.enableLogin = loginMethods && loginMethods.length > 0;
    }
  }

  private checkDeviceId() {
    const deviceId = this.route.snapshot.queryParams['deviceId'];
    if (deviceId) {
      this.toastr.info(this.translateService.instant('PAYMENT.terminalScannedNotification'), undefined, {
        timeOut: 5000,
        easeTime: 100,
        positionClass: 'toast-bottom-center',
      });
    }
  }
}

enum StoreDetailsState {
  Scan,
  Browse,
}
